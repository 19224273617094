<template>
  <BasePage
    :bannerUrl="banner"
    subTilte="文安县城发集团欢迎您"
    content="规范服务 / 阳光清廉 / 示范引领 / 降本增效"
  >
    <div class="Financial_wrap">
      <div class="Financial_subtitle">金融服务公司简介</div>
      <div class="Financial_line">
        <div class="Financial_line_left"></div>
        <div class="Financial_line_right"></div>
      </div>
      <div class="Financial_title">
        公司善于捕捉用户需求，利用丰富的行业通理与产品经验打造落地的金融产品
      </div>
      <img :src="introduce1" />
      <img :src="introduce2" />
      <img :src="introduce3" />
      <img :src="introduce4" />
      <!-- <img :src="introduce5" /> -->
    </div>
  </BasePage>
</template>

<script>
import imgData from "@/data";
import { reactive } from "vue";
import BasePage from "@/components/BasePage/BasePage.vue";
export default {
  components: { BasePage },
  setup() {
    const data = reactive({
      ...imgData.FinancialImg,
    });

    return {
      ...data,
    };
  },
};
</script>

<style scoped>
@import url("./Financial.css");
</style>

